@import 'bootstrap';
@import '../../custom.scss';
.oo-user-nav-pic {
  width: 2.5em;
  height: 2.5em;
  border-radius: 1.25em;
}

.oo-user-nav-toggle.dropdown-toggle.btn.btn-primary {
  padding: 0;
  &,
  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.oo-user-nav-dropdown {
  padding: 0;
  border: none;
  .dropdown-item {
    padding: 0.3rem 1rem;
  }
  .dropdown-item:not(:last-child) {
    border-bottom: 1px solid $secondary;
  }
}
