/*! `Custom` Bootstrap 5 theme */
$enable-grid-classes: false;
$primary: #000000;
$secondary: #6d6464d4;
$third: #057a55;
$success: #015668;
$danger: #06648c;
$danger-red: #c22b2b;
$info: #0f81c7;
$warning: #0de2ea;
$light: #ececec;
$dark: #222222;
$spacer: 1rem;
$border-width: 1px;
$btn-border-radius: 0.25rem;
$small-font-size: 0.875em;

// More utility class
.fs-7 {
  font-size: 0.9rem;
}
.fs-8 {
  font-size: 0.8rem;
}
