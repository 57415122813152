@import 'bootstrap';

body.modal-open {
  padding-right: 0 !important;
}
.modal-dialog {
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.oo-user-nav-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

#reject-reason-dropdown {
  background-color: grey;
}